import moment from "moment";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { getBuildingRequest, getBuildingRequestLM, clearBuilding } from "../../actions/buildings";
import { claimHome, getCustomerBuildings, setCurrentBuilding, unClaimHome } from "../../actions/auth";
import { connect } from "react-redux";
import "./Inter/inter.scss";
import "./HomeownerMapPage.scss";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import { AddressAutofill, config } from '@mapbox/search-js-react';
import { withTranslation, Trans } from "react-i18next";
import i18next from 'i18next';
import { listsData } from "../../helpers/audit-enums";
import CDropdown from "../CustomerPages/components/CDropdown";
import Themes from "../CustomerPages/Themes"
import "../CustomerPages/Themes.scss";
import FocusLock from 'react-focus-lock';
import CHomeRatingCard from "../CustomerPages/components/CHomeRatingCard";
import CRetrofitScenariosCard from "../CustomerPages/components/CRetrofitScenariosCard";
import CustomerWizard from "../CustomerPages/CustomerWizard";
import { toast } from "react-toastify";
import CFAQSection from "../CustomerPages/components/CFAQSection";
import CFaqButton from "../CustomerPages/components/CFaqButton";

const { onBlurOutside } = require("../../helpers/utils");

config.accessToken = mapboxgl.accessToken = 'pk.eyJ1Ijoic2ltcHRlayIsImEiOiJja3ltem9nMzMwNzhzMnZwbmNwb3hxcDFnIn0.9gGHzt9qVQHfnofHux-wtg';

let THEME;

/////

const STYLES = {
	'eng': 'mapbox://styles/simptek/clvmgtc6r045h01ph6b2r2kqy',
	'env': 'mapbox://styles/simptek/clvmh721w03yr01ql9miv6iog'
};

const E1_STYLE = 'mapbox://styles/simptek/clxksiy1101ui01qo4bfrhb0s';

const DEFAULT_SELECTION_COLOR = '#FFF';
const E1_SELECTION_COLOR	  = '#FF4';

const ABV_MAP = {
	'ALABAMA': 'AL',
	'ALASKA': 'AK',
	'AMERICAN SAMOA': 'AS',
	'ARIZONA': 'AZ',
	'ARKANSAS': 'AR',
	'CALIFORNIA': 'CA',
	'COLORADO': 'CO',
	'CONNECTICUT': 'CT',
	'DELAWARE': 'DE',
	'DISTRICT OF COLUMBIA': 'DC',
	'FEDERATED STATES OF MICRONESIA': 'FM',
	'FLORIDA': 'FL',
	'GEORGIA': 'GA',
	'GUAM': 'GU',
	'HAWAII': 'HI',
	'IDAHO': 'ID',
	'ILLINOIS': 'IL',
	'INDIANA': 'IN',
	'IOWA': 'IA',
	'KANSAS': 'KS',
	'KENTUCKY': 'KY',
	'LOUISIANA': 'LA',
	'MAINE': 'ME',
	'MARSHALL ISLANDS': 'MH',
	'MARYLAND': 'MD',
	'MASSACHUSETTS': 'MA',
	'MICHIGAN': 'MI',
	'MINNESOTA': 'MN',
	'MISSISSIPPI': 'MS',
	'MISSOURI': 'MO',
	'MONTANA': 'MT',
	'NEBRASKA': 'NE',
	'NEVADA': 'NV',
	'NEW HAMPSHIRE': 'NH',
	'NEW JERSEY': 'NJ',
	'NEW MEXICO': 'NM',
	'NEW YORK': 'NY',
	'NORTH CAROLINA': 'NC',
	'NORTH DAKOTA': 'ND',
	'NORTHERN MARIANA ISLANDS': 'MP',
	'OHIO': 'OH',
	'OKLAHOMA': 'OK',
	'OREGON': 'OR',
	'PALAU': 'PW',
	'PENNSYLVANIA': 'PA',
	'PUERTO RICO': 'PR',
	'RHODE ISLAND': 'RI',
	'SOUTH CAROLINA': 'SC',
	'SOUTH DAKOTA': 'SD',
	'TENNESSEE': 'TN',
	'TEXAS': 'TX',
	'UTAH': 'UT',
	'VERMONT': 'VT',
	'VIRGIN ISLANDS': 'VI',
	'VIRGINIA': 'VA',
	'WASHINGTON': 'WA',
	'WEST VIRGINIA': 'WV',
	'WISCONSIN': 'WI',
	'WYOMING': 'WY',
	'BRITISH COLUMBIA': 'BC',
	'ALBERTA': 'AB',
	'SASKATCHEWAN': 'SK',
	'MANITOBA': 'MB',
	'ONTARIO': 'ON',
	'QUEBEC': 'QC',
	'NEW BRUNSWICK': 'NB',
	'NOVA SCOTIA': 'NS',
	'PRINCE EDWARD ISLAND': 'PE',
	'NEWFOUNDLAND': 'NF',
	'NORTHWEST TERRITORIES': 'NT',
	'YUKON': 'YT'
};

class HomeownerMapPage extends PureComponent {
	static propTypes = {
	};

	static defaultProps = {
	};

	constructor(props) {
		super(props);

		this.T = props.t;

		document.body.className = 'public-page-body';
	
		this.mapContainer = React.createRef();
		this.map = React.createRef();
		this.inputID = `search-box-${Math.floor(Math.random() * 1e9)}`;
		this.inputCBHID = `search-box-cbh-${Math.floor(Math.random() * 1e9)}`;
		this.searchNumber = 0;

		if (window.location.href.indexOf("?deleted") >= 0) {
			window.location.href = "#/public/homeowner-map";
			toast.success(this.T("Your account has been deleted."));
		}

		const showIntro = window.localStorage.getItem('intro-modal-complete') !== 'true' &&
						  window.sessionStorage.getItem('intro-modal-hidden') !== 'true' &&
						  !this.props.currentUser;

		if (!window.GET_FF('enf-signin-flow-fix')) {
			window.sessionStorage.setItem('intro-modal-hidden', 'true');
		}

		const showTWModal = window.GET_FF('enf-signin-flow-fix') && !this.props.currentUser && !showIntro && !window.DID_SHOW_TW_INTRO;
		window.DID_SHOW_TW_INTRO = window.GET_FF('enf-signin-flow-fix') && !this.props.currentUser && !showIntro;

		if (showIntro) {
			window.TRACK_EVENT({eventId: 'homeowner-intro-map-page-show'});
		}
		else {
			this.autoFocusSearch = !window.localStorage.getItem('token');
		}

		this.state = {
			introStep: showIntro ? 1 : 5,
			homeRatingTab: 2,
			addressInfo: {
				
			},
			popupOpen: showIntro || showTWModal,
			twModalMode: showTWModal,
			showInsights: false,
			mapStyle: 'eng',
			areaSelected: "fsa",
			upgradePackageSelected: 3,
			mapSwitcherOpen: false
		};

		this.mapLabelCodes = [
			"country-label",
			"state-label",
			//"settlement-label",
			"settlement-major-label",
			"settlement-subdivision-label",
			"airport-label",
			"poi-label",
			"water-point-label",
			"water-line-label",
			"natural-point-label",
			"natural-line-label",
			"waterway-label",
			//"road-label"
		];

		this.ratings = listsData.homeRatings;

		this.mapboxSuggestionSelected = this.mapboxSuggestionSelected.bind(this);
		this.mapboxSuggestionSelectedConfirmAddress = this.mapboxSuggestionSelectedConfirmAddress.bind(this);
		this.setStyle = this.setStyle.bind(this);

		this.areaDropdownOptions = [
			{ key: "fsa", title: this.T('Neighborhood') },
			{ key: "city", title: this.T('Municipality') },
			{ key: "province", title: this.T('Province') }
		];

		THEME = this.theme = Themes.autodetect();

		if (window.location.href.includes('/homeowner-map')) {
			if (localStorage.getItem('token') === null) {
				props.dispatch(getBuildingRequest());
				props.dispatch(getBuildingRequestLM());
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {

		if (!prevProps.show && this.props.show) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			const el = document.getElementById('ph-header-cont');
			if (el) {
				el.scrollTop = 0;
			}
		}

		if (document.getElementById('hmp-intro-modal') && !this.introModalInitFocused) {
			document.getElementById('hmp-intro-modal').focus();
			this.introModalInitFocused = true;
		}
		if (document.getElementById(this.inputID) && (this.introModalJustClosed || this.autoFocusSearch)) {
			document.getElementById(this.inputID).focus();
			this.introModalJustClosed = false;
			this.modalJustClosed = false;
			this.autoFocusSearch = false;
		}
		if (document.getElementById('hmp-main-div') && this.searchResultDisplayed) {
			let div = document.getElementById('hmp-main-div');
			setTimeout(() => { if (div.focus) div.focus(); }, 10);
			this.searchResultDisplayed = false;
		}
		if (document.getElementById('hmp-how-header') && this.faqModalOpened && this.state.howModal && this.state.popupOpen) {
			let div = document.getElementById('hmp-how-title')
			setTimeout(() => { if (div.focus) div.focus(); }, 100);
			this.faqModalOpened = false;
		}

		if (this.state.homeRatingTab !== prevState.homeRatingTab || this.state.areaSelected !== prevState.areaSelected) {
			if (this.state.homeRatingTab === 1) {
				this.setState({ mapStyle: 'env' });
				this.setStyle('env');
			}
			else if (this.state.homeRatingTab === 2) {
				this.setState({ mapStyle: 'eng' });
				this.setStyle('eng');
			}
		}

		if (!prevProps.currentUser && this.props.currentUser && this.justClickedCTA) {
			this.justClickedCTA = false;
			setTimeout(async () => {
				if (this.state.addressInfo?.buildingId) {
					await this.claimHomeFromIDClick();
				}
				else {
					await this.claimHomeFromMapDataClick();
				}
				this.modalOpenClick('confirm-claim')();
			}, 1);
		}
	}

	componentDidMount() {
		if (document.getElementById('hmp-intro-modal') && !this.introModalInitFocused) {
			document.getElementById('hmp-intro-modal').focus();
			this.introModalInitFocused = true;
		}
		if (!this.map.current && this.mapContainer.current) {
			this.map.current = new mapboxgl.Map({
				container: this.mapContainer.current,
				style: this.theme.whiteBuildingsMap ? E1_STYLE : STYLES[this.state.mapStyle],
				center: [-99.9816749, 58.2393298],
				zoom: 3.5,
				attributionControl: false,
				pitch: 45
			});
			if (window.ORG_INFO.bounding_box) {
				const coords = window.ORG_INFO.bounding_box.split(",").map((e) => parseFloat(e));
				if (this.theme.zoomFitBounds) {
					this.map.current.fitBounds([
						[Math.min(coords[1], coords[3]), Math.min(coords[0], coords[2])],
						[Math.max(coords[1], coords[3]), Math.max(coords[0], coords[2])]
					]);
				}
				else {
					if (this.theme.advisorExperience) {
						this.map.current.setZoom(7);
					}
					else {
						this.map.current.setZoom(12);
					}
					this.map.current.setCenter([(coords[1] + coords[3]) * 0.5, (coords[0] + coords[2]) * 0.5]);
				}
			}
			else if (window.ORG_INFO.user_loc) {
				const center = window.ORG_INFO.user_loc;
				this.map.current.setZoom(12);
				this.map.current.setCenter([center.lon, center.lat]);
			}

			this.map.current.on('click', (e) => {
				let lng = e.lngLat.lng,
					lat = e.lngLat.lat;
				this.searchClick({lng, lat});
				this.LAST_SEARCH = 'click';
			});
			this.map.current.on('styledata', () => {
				for (let label of this.mapLabelCodes) {
					const map = this.map.current;
					map.setLayoutProperty(label, 'text-field', [
						'get',
						`name_${this.T('hmp-lang-code')}`.toLowerCase()
					]);
				}
				if (this.state.showInsights && this.selectedBuildingPolygon && this.justChangedStyle) {
					this.justChangedStyle = false;
					const map = this.map.current;
					try {
						map.removeLayer('selected_building_2');
						map.removeSource('selected_building_2');
					} catch (err) { }
					try {
						map.removeLayer('selected_building');
						map.removeSource('selected_building');
					} catch (err) { }
					try {
						map.addSource('selected_building', {
							'type': 'geojson',
							'data': this.scalePoly(this.selectedBuildingPolygon, 0.1)
						});
						map.addSource('selected_building_2', {
							'type': 'geojson',
							'data': this.scalePoly(this.selectedBuildingPolygon, 0.01)
						});
						map.addLayer({
							'id': 'selected_building',
							'type': 'fill-extrusion',
							'source': 'selected_building',
							'layout': {},
							'paint': {
								'fill-extrusion-color': this.theme.whiteBuildingsMap ? E1_SELECTION_COLOR : DEFAULT_SELECTION_COLOR,
								'fill-extrusion-height': 1, 
							}
						});	
					}
					catch (err) { }
				}
			});
		}

		setTimeout(async () => {
			if (this.props.currentUser && this.props.building && !this.mapLoadedOnce && this.props.building?.address && this.props.building.buildingId !== 'MOCK-DATA-123') {
				this.searchClickAddress(this.props.building.normalized_address ?? this.props.building.address);
				this.LAST_SEARCH = 'auto';
				this.mapLoadedOnce = true;
			}
			else if (this.props.lastUnclaimedBuildingId && !this.mapLoadedOnce && (!this.props?.building?.buildingId || this.props?.building?.buildingId?.contains('MOCK-DATA-123'))) {
				this.setState({ loading: true });
				await this.props.dispatch(getBuildingRequest(this.props.lastUnclaimedBuildingId, true));
				await this.props.dispatch(getBuildingRequestLM(this.props.lastUnclaimedBuildingId, true));
				this.searchClickAddress(this.props.building.normalized_address ?? this.props.building.address);
				this.LAST_SEARCH = 'auto';
				this.mapLoadedOnce = true;
			}
		}, 100)
	}

	getRInfo() {
		return this.props.model?.__report_info;
	}

	modalOpenClick(modalId) {
		return () => {
			if (modalId === 'faq-rating') {
				this.faqModalOpened = true;
				this.setState({
					howModal: true,
					popupOpen: true,
					faqExpand: -1
				});
			}
			else if (modalId === 'faq-upgrades') {
				this.faqModalOpened = true;
				this.setState({
					howRetrofitModal: true,
					popupOpen: true,
					faqExpand: -1
				});
			}
			else if (modalId === 'confirm-claim') {
				this.faqModalOpened = true;
				this.setState({
					confirmClaimModal: true,
					popupOpen: true
				});
			}
		}
	}

	getRInfoPackage() {
		let rinfo = this.getRInfo();
		if (!rinfo) {
			return undefined;
		}
		let keys = [ '', 'Good_Package', 'Better_Package', 'Best_Package' ];
		return rinfo.bundle_recommendations[keys[this.state.upgradePackageSelected]];
	}

	polyCenter(geojson) { // center of area
		let min = [ 1e9, 1e9 ], max = [ -1e9, -1e9 ];
		let minMaxScan = (arr) => {
			for (let item of arr) {
				if (typeof item[0] == 'number' && item.length == 2) {
					min[0] = Math.min(min[0], item[0]);
					min[1] = Math.min(min[1], item[1]);
					max[0] = Math.max(max[0], item[0]);
					max[1] = Math.max(max[1], item[1]);
				}
				else {
					minMaxScan(item);
				}
			}
		};
		minMaxScan(geojson.geometry.coordinates);
		return [ (min[0] + max[0]) * 0.5, (min[1] + max[1]) * 0.5 ];
	}

	scalePoly(_geojson, percent) {
		let geojson = JSON.parse(JSON.stringify(_geojson));
		let coa = this.polyCenter(geojson);
		let scaleScan = (arr) => {
			for (let item of arr) {
				if (typeof item[0] == 'number' && item.length == 2) {
					item[0] = (item[0] - coa[0]) * (1 + percent) + coa[0];
					item[1] = (item[1] - coa[1]) * (1 + percent) + coa[1];
				}
				else {
					scaleScan(item);
				}
			}
		};
		scaleScan(geojson.geometry.coordinates);
		if (geojson?.properties?.height) {
			geojson.properties.height *= (1 + percent);
		}
		geojson.type = 'Feature';
		return geojson;
	}

	setStyle(style) {
		if (this.map && this.map.current) {
			const map = this.map.current;
			this.justChangedStyle = true;
			map.setStyle(STYLES[style]);
			console.warn(`Setting map style to: ${STYLES[style]}`);
		}
	}

	setSelectedBuildingPolygon(polygon, center) {
		this.setState({
			last_building_polygon: { polygon, center }
		});
		setTimeout(() => {
			// If the user has searched by address and come up with an untrusted or missing result, pan map but don't select building
			if (!(!this.props.currentUser || !this.state.onboardData || (this.onboardDataValid(this.state.onboardData) || this.state?.addressInfo?.insight?.buildingId)) && this.state.lastSearchType === 'address') {
				const map = this.map.current;
				if (polygon) {
					center = this.polyCenter(polygon);
				}
				map.flyTo({
					center: center,
					zoom: 18,
					padding: window.innerWidth < 848 ? {top: 0, bottom: Math.round(window.innerHeight / 2.5), left: 0, right: 0} : {top: 0, bottom: 0, left: 450, right: 0}
				});
				this.setState({
					last_building_polygon: null
				});
				try {
					map.removeLayer('selected_building_2');
					map.removeSource('selected_building_2');
				} catch (err) { }
				try {
					map.removeLayer('selected_building');
					map.removeSource('selected_building');
				} catch (err) { }
				return;
			}
			// Otherwise, select building and pan map
			if (this.map.current) {
				const map = this.map.current;
				if (polygon) {
					center = this.polyCenter(polygon);
				}
				map.flyTo({
					center: center,
					zoom: 18,
					padding: window.innerWidth < 848 ? {top: 0, bottom: Math.round(window.innerHeight / 2.5), left: 0, right: 0} : {top: 0, bottom: 0, left: 450, right: 0}
				});
				try {
					map.removeLayer('selected_building_2');
					map.removeSource('selected_building_2');
				} catch (err) { }
				try {
					map.removeLayer('selected_building');
					map.removeSource('selected_building');
				} catch (err) { }
				if (polygon) {
					this.selectedBuildingPolygon = polygon;
					map.addSource('selected_building', {
						'type': 'geojson',
						'data': this.scalePoly(polygon, 0.1)
					});
					map.addSource('selected_building_2', {
						'type': 'geojson',
						'data': this.scalePoly(polygon, 0.01)
					});
					map.addLayer({
						'id': 'selected_building',
						'type': 'fill-extrusion',
						'source': 'selected_building',
						'layout': {},
						'paint': {
							'fill-extrusion-color': this.theme.whiteBuildingsMap ? E1_SELECTION_COLOR : DEFAULT_SELECTION_COLOR,
							'fill-extrusion-height': 1, 
						}
					});
					/*map.addLayer({
						'id': 'selected_building_2',
						'type': 'fill-extrusion',
						'source': 'selected_building_2',
						'paint': {
							'fill-extrusion-color': this.ratings[(ret.energyUse || 0) - 1].color,
							'fill-extrusion-height': ['get', 'height'],					 
						}
					});*/
				}
				else {
					map.addSource('selected_building', {
						'type': 'geojson',
						'data': {
							type: "Feature",
							geometry: {
								"type": "Point",
								"coordinates": center
							}
						}
					});
					map.addLayer({
						'id': 'selected_building',
						'type': 'circle',
						'source': 'selected_building',
						'paint': {
							'circle-radius': 5.,
							'circle-color': this.theme.whiteBuildingsMap ? E1_SELECTION_COLOR : DEFAULT_SELECTION_COLOR
						}
					});
				}
			}
		}, 10);
	}

	switchLanguage(lang) {
		i18next.changeLanguage(lang);
		if (this.map && this.map.current) {
			const map = this.map.current;
			for (let label of this.mapLabelCodes) {
				map.setLayoutProperty(label, 'text-field', [
					'get',
					`name_${lang}`.toLowerCase()
				]);
			}
		}
	}

	searchClick(coord) {
		axios.get('/public/reverse-geocode', { params: { lng: coord.lng, lat: coord.lat, language: `${this.T("hmp-lang-code")}`.toLowerCase() }}).then(res => {
			const inputElement = document.getElementById(this.inputID);
			let building_polygon = res.data?.home?.properties?.building_polygon;
			if (building_polygon) {
				try {
					building_polygon = { type: 'MultiPolygon', geometry: JSON.parse(building_polygon) };
				}
				catch (err) {
					building_polygon = null;
				}
			}
			if (document.activeElement) {
				document.activeElement.blur();
			}
			this.searchResultDisplayed = true;
			const ret = res.data;
			if (!ret.rgc?.place_name || !ret.home?.properties) {
				ret.addressFound = false;
				this.setState({ addressInfo: ret, showInsights: true, shareLevel: 1, loading: false, mobileMin: false, ratingTab: this.state.mapStyle === 'eng' ? 2 : 1, hasBuildingPolygon: false, insightFromMap: true, lastSearchType: 'click' });
			}
			else {
				ret.addressFound = true;
				ret.energyUse = ret?.home?.properties?.gj_per_m2_level;
				ret.envImpact = ret?.home?.properties?.ghg_per_m2_level;
				ret.energyUseValue = ret?.home?.properties?.gj_per_year;
				ret.envImpactValue = ret?.home?.properties?.total_ghg;
				ret.displayAddress = `${ret.rgc.place_name}`.trim();
				ret.overrideStats = ret?.home?.properties;
				inputElement.value = ret.displayAddress;
				this.setSelectedBuildingPolygon(building_polygon, [coord.lng, coord.lat]);
				let shareLevel = 2;
				let insightFromMap = true;
				if (ret?.insight?.buildingId) {
					this.props.dispatch(getBuildingRequest(ret?.insight?.buildingId, true));
					this.props.dispatch(getBuildingRequestLM(ret?.insight?.buildingId, true));
					this.props.dispatch(getCustomerBuildings());
					shareLevel = ret?.rgc?.data_sharing_level;
					ret.overrideStats = null;
					insightFromMap = false;
				}
				else {
					this.props.dispatch(clearBuilding());
					this.props.dispatch(getBuildingRequest());
					this.props.dispatch(getBuildingRequestLM());
				}
				if (ret?.home?.properties) {
					ret.home.properties.address = ret.displayAddress;
				}
				this.setState({ addressInfo: ret, showInsights: true, shareLevel, loading: false, mobileMin: false, ratingTab: this.state.mapStyle === 'eng' ? 2 : 1, hasBuildingPolygon: !!building_polygon, insightFromMap, onboardData: ret?.home?.properties, onboardSurvey: false, lastSearchType: 'click' });
			}
		});
	}

	searchClickAddress(address, coord) {
		this.searchNumber += 1;
		const thisSearchNumber = this.searchNumber;

		const inputElement = document.getElementById(this.inputID);
		address = address ?? inputElement.value.trim();
		if (!address.length && !coord) {
			return;
		}
		this.setState({ showInsights: false, loading: true });
		axios.get('/public/geocode', { params: { address, language: `${this.T("hmp-lang-code")}`.toLowerCase() }}).then(res => {
			if (thisSearchNumber != this.searchNumber) {
				return;
			}
			if (document.activeElement) {
				document.activeElement.blur();
			}
			this.searchResultDisplayed = true;
			const ret = res.data;
			ret.addressFound = !!(ret.longitude || ret.latitue);
			ret.energyUse = ret.gj_per_m2_level;
			ret.envImpact = ret.ghg_per_m2_level;
			ret.energyUseValue = ret.gj_per_year;
			ret.envImpactValue = ret.total_ghg;
			if (!ret.street_number) {
				throw "building not found";
			}
			ret.displayAddress = `${ret.street_number || ''} ${ret.street_name || ''}, ${ret.city}, ${ABV_MAP[`${ret.province}`.toUpperCase()] || ret.province}`.trim();
			inputElement.value = ret.displayAddress;
			if (ret.insight_from_map) {
				const minsight = ret.insight_from_map;
				if (minsight.properties) {
					minsight.properties.address = ret.displayAddress;
				}
				let building_polygon = minsight.properties?.building_polygon;
				if (building_polygon) {
					try {
						building_polygon = { type: 'MultiPolygon', geometry: JSON.parse(building_polygon) };
					}
					catch (err) {
						building_polygon = null;
					}
				}
				ret.addressFound = !!building_polygon;
				ret.energyUse = minsight.properties?.gj_per_m2_level;
				ret.envImpact = minsight.properties?.ghg_per_m2_level;
				ret.energyUseValue = minsight.properties?.gj_per_year;
				ret.envImpactValue = minsight.properties?.total_ghg;
				ret.overrideStats = minsight.properties;
				this.setSelectedBuildingPolygon(building_polygon, [ret.longitude, ret.latitude]);
				let shareLevel = 2;
				this.setState({ addressInfo: ret, showInsights: true, shareLevel, loading: false, mobileMin: false, ratingTab: this.state.mapStyle == 'eng' ? 2 : 1, hasBuildingPolygon: !!building_polygon, insightFromMap: !!minsight.properties?.storeys, onboardData: minsight.properties?.storeys ? minsight.properties : null, onboardSurvey: false, lastSearchType: 'address' });
			} else {
				this.setSelectedBuildingPolygon(ret.building_polygon, [ret.longitude, ret.latitude]);
				if (ret.buildingId) {
					this.props.dispatch(getBuildingRequest(ret.buildingId, !this.props.currentUser));
					this.props.dispatch(getBuildingRequestLM(ret.buildingId, !this.props.currentUser));
					this.props.dispatch(getCustomerBuildings());
				}
				else {
					this.props.dispatch(clearBuilding());
					this.props.dispatch(getBuildingRequest());
					this.props.dispatch(getBuildingRequestLM());
				}
				this.setState({ addressInfo: ret, showInsights: true, shareLevel: ret.data_sharing_level || 1, loading: false, mobileMin: false, ratingTab: this.state.mapStyle == 'eng' ? 2 : 1, hasBuildingPolygon: !!ret.building_polygon, insightFromMap: false, onboardData: null, onboardSurvey: false, lastSearchType: 'address' });
			}
		}).catch((err) => {
			if (thisSearchNumber != this.searchNumber) {
				return;
			}
			if (document.activeElement) {
				document.activeElement.blur();
			}
			this.searchResultDisplayed = true;
			const data = err?.response?.data;
			if (data && (data.longitude || data.latitude)) {
				const map = this.map.current;
				if (map) {
					map.flyTo({ center: [data.longitude, data.latitude], zoom: 13 });
				}
				this.setState({ addressInfo: { addressFound: false, address, message: data.message === 'Unable to find building at address.' ? "We weren’t able to find a building with that address." : 'We weren\'t able to locate this address.', message2: data.message === 'Unable to find building at address.' ? (<Trans i18nKey="add-my-home-cta">Go to <a className="link-with-underline" href="https://climative.atlassian.net/wiki/external/MDBjYmQzNzU3ZmIyNGNlMzllNzNiNmI2YWUzMzkxZGI" target="_blank">Climative FAQ: Find My Home</a> for more info.</Trans>) : null }, showInsights: data.message === 'Unable to find building at address.', loading: false, mobileMin: false, insightFromMap: false, onboardData: null, onboardSurvey: false, lastSearchType: 'address' });
			}
			else {
				this.setState({ addressInfo: { addressFound: false, address, message: 'Unable to locate address.' }, showInsights: true, loading: false, mobileMin: false, insightFromMap: false, onboardData: null, onboardSurvey: false, lastSearchType: 'address' });
			}
		});
	}

	clearSearch() {
		this.searchResultDisplayed = false;
		const inputElement = document.getElementById(this.inputID);
		inputElement.value = "";
		document.documentElement.scrollTop = document.body.scrollTop = 0;
		this.modalJustClosed = true;
		this.setState({ showInsights: false, insightFromMap: true });
		try {
			this.map.current.removeLayer('selected_building_2');
			this.map.current.removeSource('selected_building_2');
		} catch (err) { }
		try {
			this.map.current.removeLayer('selected_building');
			this.map.current.removeSource('selected_building');
		} catch (err) { }
	}
	
	clickCTA() {
		this.setState({
			howModal: false,
			popupOpen: false,
			howRetrofitModal: false
		});
		this.modalJustClosed = true;
		this.faqModalOpened = false; 
		if (!this.state.onboardData || (this.onboardDataValid(this.state.onboardData) || this.state?.addressInfo?.insight?.buildingId)) {
			this.justClickedCTA = true;
		}
		else {
			this.justClickedCTA = false;
			this.setState({ onboardSurvey: true, rand: Math.random(), confirmAddress: document.getElementById(this.inputID).value, addressInUseError: false });
		}
		this.ctaClicked = true;
		this.props.openLoginModal();
	}

	mapboxSuggestionSelected(res) {
		const feature = res.features[0];
		const address = feature?.properties?.full_address;
		setTimeout(() => {
			if (feature.properties.accuracy === 'rooftop' || feature.properties.accuracy === 'point') {
				this.searchClickAddress(address, { lng: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1] });
				this.LAST_SEARCH = 'address';
			}
			else if (feature.properties.accuracy === 'interpolated') {
				const inputElement = document.getElementById(this.inputID);
				inputElement.value = `${feature.properties.address_line1}, ${feature.properties.address_level2}, ${ABV_MAP[feature.properties.address_level1.toUpperCase()]}`;
				this.searchClickAddress(address, { lng: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1] });	
				this.LAST_SEARCH = 'address';
			}
			else {
				const map = this.map.current;
				if (map) {
					map.flyTo({
						center: feature.geometry.coordinates,
						zoom: 13
					});
				}
			}	
		}, 1);
	}

	mapboxSuggestionSelectedConfirmAddress(res) {
		const feature = res.features[0];
		const address = feature?.properties?.full_address;
		this.setState({ confirmAddress: address, addressChanged: address !== document.getElementById(this.inputID).value })
		axios.get('/public/check-address/?address=' + encodeURIComponent(address)).then((res) => {
			this.setState({ addressInUseError: !!res?.data?.address_exists });
		}).catch((err) => {
			this.setState({ addressInUseError: false });
		})
	}

	onRadioKeydown(e){
		if(e.key == 'ArrowDown'){
		  e.preventDefault();
		  const next = e.target.nextElementSibling;
		  if(next){
			next.focus();
			next.click();
		  }
		} else if(e.key == 'ArrowUp'){
		  e.preventDefault();
		  const prev = e.target.previousElementSibling;
		  if(prev){
			prev.focus();
			prev.click();
		  }
		}
	}

	renderTWModal() {
		return (<div className={"hmp-how-bg tw-modal " + (!this.state.twModalMode ? 'fade-hide' : 'fade-show')}>
			{this.state.twModalMode ? <FocusLock>
				<div role="dialog" aria-modal="true" aria-labelledby="hmp-tw-title" aria-label={this.T("Do you have an account?")} className={"hmp-tw " + (!this.state.twModalMode ? 'hmp-hide' : 'hmp-show')} aria-live="polite">
					<div className="hmp-tw-title">{this.T("Do you have an account?")}</div>
					<div className="hmp-tw-subtitle">{this.T("Sign in if you already have an account.")}</div>
					<div className="hmp-tw-btns">
						<button className="hmp-tw-btn" onClick={() => { this.autoFocusSearch = true; this.setState({ twModalMode: false }); this.modalJustClosed = true; }}>{this.T("No, I don't")}</button>
						<button className="hmp-tw-btn" onClick={() => { this.setState({ twModalMode: false }); this.modalJustClosed = true; this.props.openLoginModal(); }}>{this.T("Yes, I have an account")}</button>
					</div>
					<div className='ph-lang-switcher hmp-tw-ls' onBlur={(e) => onBlurOutside(e, () => this.setState({ langShow: false }))}>
						<button className='phls-btn' aria-label={this.T('Change Language') + ' (' + (this.T('hmp-lang-code') === 'EN' ? 'English Selected' : 'French Selected') + ')'} aria-expanded={this.state.langShow} onClick={() => { if (!this.theme.langSwitchDisabled) { this.setState({langShow: !this.state.langShow}) } }}><img alt="" src="/images/lang-icon.svg"/>{this.T('hmp-lang-code')}</button>
						<div role='radiogroup' className={'phls-dropdown ' + (this.state.langShow ? 'phls-dd-show' : '')}>
							<button role="radio" tabIndex={this.T('hmp-lang-code') === 'EN' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "true" : "false"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'EN' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('en')}><img alt={''} src={this.T('hmp-lang-code') === 'EN' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> English (EN)</button>
							<button role="radio" tabIndex={this.T('hmp-lang-code') === 'FR' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "false" : "true"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'FR' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('fr')}><img alt={''} src={this.T('hmp-lang-code') === 'FR' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> Français (FR)</button>
						</div>
					</div>
				</div>
			</FocusLock> : undefined}
		</div>);
	}

	renderIntro(step) {
		const NUM_STEPS = 4;
		if (step < 1) {
			return;
		}

		let hideIntro = this.state.hideIntro || step > NUM_STEPS;

		let _step = Math.min(step, NUM_STEPS);

		let img = `/images/hmp-intro-${_step}.svg`;
		let i18nSuffix = '';
		let title = [ 'Get Your Free Personalized Home Energy Report',
					  'First: Claim Your Home to Access Your Home Energy Report',
					  'Then: Improve Your Retrofit Recommendations with the Building Questionnaire',
					  'Finally: Take Action!' ][_step-1];
		let content = [ 'Save money, make your home more comfortable, and lower your emissions with your personalized Home Energy Report.', 
					    'Enter an address or click a home on the Map to view its Home Rating. Log in to view your detailed Report that includes retrofit recommendations.',
						'Fill in more information to improve how well your Report and recommendations represent your home.',
					    'Once you’ve understood your retrofit recommendations, it’s time to take action! The app will direct you to resources like funding support and energy efficiency professionals.' ][_step-1];
		if (THEME.advisorExperience) {
			i18nSuffix = '-e1';
			title = [
				'Welcome to Your Personal Energy Navigator!',
				'First: Create Account to Sign Up for Your Video Home Energy Assessment',
				'Next: Video Home Energy Assessment with an Energy Advisor',
				'Then: Get Your Report and Take Action!'
			][_step-1];
			content = [
				'This application will help you book a Video Home Energy Assessment, see the results in an easy-to-understand way, and more!',
				'Once you create an account and claim your home with your account, you will be able to book a Video Home Energy Assessment.',
				'Next, you will have your video home energy assessment, a video call with an energy advisor who will model a home energy retrofit plan for you.',
				'After your energy advisor publishes the results of your energy assessment, you can see them here. After you perform your retrofits, you can come back and follow the same process for the post-upgrade video home energy assessment too!'
			][_step-1];
		}
		let imgAlt = [
			'People discussing energy charts on a computer',
			'People reviewing a home assessment',
			'People filling out a questionnaire',
			'People planning home energy upgrades',
		][_step-1];
		content = content.split('\n');
		return (<div className={"hmp-intro-bg " + (hideIntro ? 'fade-hide' : 'fade-show')}>
			{!hideIntro ? <FocusLock>
				<div role="dialog" aria-modal="true" aria-labelledby="hmp-intro-title" aria-label={this.T("Intro Model")} className={"hmp-intro " + (hideIntro ? 'hmp-hide' : 'hmp-show')} aria-live="polite">
					<div className='ph-lang-switcher' onBlur={(e) => onBlurOutside(e, () => this.setState({ langShow: false }))}>
						<button className='phls-btn' aria-label={this.T('Change Language') + ' (' + (this.T('hmp-lang-code') === 'EN' ? 'English Selected' : 'French Selected') + ')'} aria-expanded={this.state.langShow} onClick={() => { if (!this.theme.langSwitchDisabled) { this.setState({langShow: !this.state.langShow}) } }}><img alt="" src="/images/lang-icon.svg"/>{this.T('hmp-lang-code') === 'EN' ? 'English' : 'Français'}</button>
						<div role='radiogroup' className={'phls-dropdown ' + (this.state.langShow ? 'phls-dd-show' : '')}>
							<button role="radio" tabIndex={this.T('hmp-lang-code') === 'EN' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "true" : "false"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'EN' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('en')}><img alt={''} src={this.T('hmp-lang-code') === 'EN' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> English (EN)</button>
							<button role="radio" tabIndex={this.T('hmp-lang-code') === 'FR' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "false" : "true"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'FR' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('fr')}><img alt={''} src={this.T('hmp-lang-code') === 'FR' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> Français (FR)</button>
						</div>
					</div>
					<img className="intro-img" src={img} key={'intro-img-' + _step} alt="" />
					<div className="intro-right">
						<div className="intro-top">
							<div id="hmp-intro-modal" className="top-step"><Trans i18nKey={"hmp-intro-step-no-" + _step}>{{_step}} of {{NUM_STEPS}}</Trans></div>
							<button className="skip-step" onClick={() => {
								this.setState({hideIntro: true, popupOpen: false});
								this.introModalInitFocused = false;
								this.modalJustClosed = true;
								window.scrollTo(0, 0);
								window.TRACK_EVENT({eventId: 'homeowner-intro-map-page-skip'});
							}}><Trans i18nKey={"hmp-intro-skip"}>Skip</Trans></button>
						</div>
						<h2 className="intro-title" id="hmp-intro-title"><Trans i18nKey={"hmp-intro-step-title-" + _step + i18nSuffix}>{title}</Trans></h2>
						<div className="intro-content" id="hmp-intro-desc">{content.map((line, idx) => (
							<div className="content-line">{line && <Trans i18nKey={"hmp-intro-step-" + _step + "-content-line-" + idx + i18nSuffix}>{line}</Trans>}</div>	
						))}</div>
						<div className="intro-buttons">
							{(_step > 1) && <button tabIndex={25} className="intro-back" onClick={() => {
								this.introModalInitFocused = false;
								this.setState({introStep: _step-1})
							}}><Trans i18nKey={"hmp-intro-back"}>Back</Trans></button>}
							<button tabIndex={26} className="intro-next" onClick={() => {
								this.introModalInitFocused = false;
								this.setState({introStep: _step+1});
								window.scrollTo(0, 0);
								if (_step == NUM_STEPS) {
									this.setState({ popupOpen: false });
									this.modalJustClosed = true;
									this.introModalJustClosed = true;
									window.localStorage.setItem('intro-modal-complete', 'true')
									window.TRACK_EVENT({eventId: 'homeowner-intro-map-page-complete'});
								}
							}}>{_step == NUM_STEPS ? this.T('GO CLAIM MY HOME') : this.T('Next')}</button>
						</div>
					</div>
				</div>
			</FocusLock> : undefined}
		</div>);
	}

	renderFAQModal() {

		return (<div className={"hmp-how-bg faq-modal " + (!this.state.howModal ? 'fade-hide' : 'fade-show')}>
			{this.state.howModal ? <FocusLock>
				<div role="dialog" aria-modal="true" aria-labelledby="hmp-how-title" aria-label={this.T("FAQ Modal")} className={"hmp-how " + (!this.state.howModal ? 'hmp-hide' : 'hmp-show')} aria-live="polite">
					<div className="how-header" id="hmp-how-header">
						<h2 className="how-title" id="hmp-how-title"><Trans i18nKey={"hmp-how-title"}>Frequently Asked Questions</Trans></h2>
						<button tabIndex={41} className="how-x-button" onClick={() => {this.setState({ howModal: false, popupOpen: false }); this.modalJustClosed = true; this.faqModalOpened = false; }} ><img alt={this.T("Close FAQ")} src="/images/hmp-x-icon.svg" /></button>
					</div>
					<div className="hmp-faq-section-wrap">
						<CFAQSection section="Map > Home Rating" needsCard={true} />
					</div>
				</div>
			</FocusLock> : undefined}
		</div>);
	}

	renderRetrofitFAQModal() {

		return (<div className={"hmp-how-bg faq-modal " + (!this.state.howRetrofitModal ? 'fade-hide' : 'fade-show')}>
			{this.state.howRetrofitModal ? <FocusLock>
				<div role="dialog" aria-modal="true" aria-labelledby="hmp-how-title" aria-label={this.T("FAQ Modal")} className={"hmp-how " + (!this.state.howRetrofitModal ? 'hmp-hide' : 'hmp-show')} aria-live="polite">
					<div className="how-header" id="hmp-how-header">
						<h2 className="how-title" id="hmp-how-title"><Trans i18nKey={"hmp-how-title"}>Frequently Asked Questions</Trans></h2>
						<button tabIndex={41} className="how-x-button" onClick={() => {this.setState({ howRetrofitModal: false, popupOpen: false }); this.modalJustClosed = true; this.faqModalOpened = false; }} ><img alt={this.T("Close FAQ")} src="/images/hmp-x-icon.svg" /></button>
					</div>
					<div className="hmp-faq-section-wrap">
						<CFAQSection section="Report > Home Rating" needsCard={true}  />
					</div>
				</div>
			</FocusLock> : undefined}
		</div>);
	}

	renderConfirmClaimModal() {

		const closeModal = () => {
			this.setState({ confirmClaimModal: false, popupOpen: false });
			this.modalJustClosed = true;
			this.confirmClaimModalOpened = false;
		};

		const finishClaiming = async () => {
			this.setState({ confirmClaimModal: false, popupOpen: false, loading: true, loadingClaiming: true });
			this.modalJustClosed = true;
			this.confirmClaimModalOpened = false;

			await this.props.dispatch(claimHome(this.state.addressInfo?.buildingId ?? this.state.addressInfo?.insight?.buildingId, undefined, undefined, THEME));
			window.TRACK_EVENT({eventId: 'claim-home', lastSearch: this.LAST_SEARCH, clickedClaimNotSignedIn: this.ctaClicked});

			this.setState({ loading: false, onboardData: null, insightFromMap: false, loadingClaiming: false });
			this.ctaClicked = false;
		};

		return (<div className={"hmp-how-bg cc-modal " + (!this.state.confirmClaimModal ? 'fade-hide' : 'fade-show')}>
			{this.state.confirmClaimModal ? <FocusLock>
				<div role="dialog" aria-modal="true" aria-labelledby="hmp-confirm-claim-title" aria-label={this.T("Confirm this is your Home")} className={"hmp-how " + (!this.state.confirmClaimModal ? 'hmp-hide' : 'hmp-show')} aria-live="polite">
					<div className="how-header" id="hmp-how-header">
						<h2 className="how-title" id="hmp-confirm-claim-title"><Trans i18nKey={"hmp-confirm-claim-title"}>Confirm claiming this home?</Trans></h2>
						<button tabIndex={41} className="how-x-button" onClick={closeModal} ><img alt={this.T("Close Modal")} src="/images/hmp-x-icon.svg" /></button>
					</div>
					<div className="hmp-cc-body">
						<div className="hmp-cc-addr">{this.state.addressInfo.displayAddress}</div>
						<div className="hmp-cc-btn-cont">
							<button className="hmp-cc-confirm-btn" onClick={finishClaiming}>{this.T("Yes, this is my home")}</button>
							<button className="hmp-cc-no-btn" onClick={closeModal}>{this.T("No")}</button>
						</div>
					</div>
				</div>
			</FocusLock> : undefined}
		</div>);
	}

	onboardDataValid(data) {
		if (!data || !data.storeys || !data.heated_floor_area || !data.house_type || !data.primary_heating_fuel_type || this.state.addressInUseError) {
			return false;
		}
		return true;
	}

	async claimHomeFromMapDataClick () {
		this.setState({ loading: true, loadingClaiming: true });
		this.ctaClicked = false;
		let id = await this.props.dispatch(claimHome(null, {...this.state.onboardData, building_polygon: this.state.last_building_polygon}, window.ORG_INFO?.org_id, THEME, true));
		window.TRACK_EVENT({eventId: 'claim-home', lastSearch: this.LAST_SEARCH});
		this.state.addressInfo.buildingId = id;
		this.setState({ loading: false, onboardData: null, insightFromMap: false, loadingClaiming: false, addressInfo: this.state.addressInfo });
		this.modalOpenClick('confirm-claim')();
	};

	async claimHomeFromIDClick () {
		this.setState({ loading: true, loadingClaiming: true });
		this.ctaClicked = false;
		await this.props.dispatch(claimHome(this.state.addressInfo?.buildingId ?? this.state.addressInfo?.insight?.buildingId, undefined, undefined, THEME, true));
		window.TRACK_EVENT({eventId: 'claim-home', lastSearch: this.LAST_SEARCH});
		this.setState({ loading: false, onboardData: null, insightFromMap: false, loadingClaiming: false });
		this.modalOpenClick('confirm-claim')();
	};

	async claimHomeFromMiniSurveyClick (data) {
		let onboardData = {
			...(this.state.onboardData),
			footprint_area_sqft: data.footprint,
			house_type: data.house_type,
			storeys: data.storeys,
			heated_floor_area: data.total_floor_area_sqft,
			year_built: data.year_built,
			changed_address: document.getElementById(this.inputCBHID).value !== document.getElementById(this.inputID).value ? document.getElementById(this.inputCBHID).value : undefined,
			building_polygon: this.state.last_building_polygon,
			data_source: "homeowner"
		};
		this.setState({ loading: true, loadingClaiming: true });
		this.ctaClicked = false;
		let id = await this.props.dispatch(claimHome(null, onboardData, window.ORG_INFO?.org_id, THEME, true));
		window.TRACK_EVENT({eventId: 'claim-home', lastSearch: this.LAST_SEARCH});
		this.state.addressInfo.buildingId = id;
		this.setState({ loading: false, onboardData: null, insightFromMap: false, loadingClaiming: false, addressInfo: this.state.addressInfo });
		this.modalOpenClick('confirm-claim')();
	};

	render() {

		const currentBuildingIsMine = this.state.insightFromMap || !this.state?.addressInfo?.buildingId ? false : this.props.currentBuildingIsMine;

		window.HOLD_LOGIN_REDIRECT = !!this.state?.addressInfo?.addressFound && this.props.show;

		if (this.props.show) {
			if (this.theme.hasAdvisor) {
				document.title = this.T('Home Map') + ' - ' + this.T('Energy Navigator');
			}
			else {
				document.title = this.T('Home Map') + ' - ' + this.T('Climative  Low Carbon Communities');
			}
		}

		document.body.className = "customer-page " + this.theme.className;

		let backToMyHomeBtn;
		if (this.props?.currentUser?.primaryBuildingId) {
			let options = [];
			if (this.props.customerBuildings) {
				for (let building of this.props.customerBuildings) {
					options.push({
						title: building.address,
						id: building.id,
						naddr: building.normalized_address,
						key: building.id
					});
				}
			}
			if (options.length > 1) {
				let selected = options.find((e) => e.id === (this.props.currentUser?.currentBuildingId ?? this.props?.currentUser?.primaryBuildingId));
				backToMyHomeBtn = (<CDropdown style="btmh" pullUp={!this.state.showInsights} defaultValue={selected} title={currentBuildingIsMine ? this.T("SWITCH HOME") : this.T("BACK TO MY HOME")} options={options} onChange={(val) => {
					this.searchClickAddress(val.naddr);
					this.props.dispatch(setCurrentBuilding(val.id));
					this.LAST_SEARCH = 'back to my home';
				}}></CDropdown>);
			}
			else if (options.length === 1 && (!currentBuildingIsMine || !this.state.showInsights)) {
				const opt = options[0];
				backToMyHomeBtn = (<button className="rhb-btn btmh-button" onClick={() => {
					this.searchClickAddress(opt.naddr);
					this.props.dispatch(setCurrentBuilding(opt.id));
					this.LAST_SEARCH = 'back to my home';
				}}>{this.T("Back To My Home")}</button>);
			}
		}

		let unclaimDropdown;
		if (currentBuildingIsMine) {
			unclaimDropdown = (
				<CDropdown
					aria-label={this.T('More Homeowner Actions')}
					style="res-more"
					selChangeEventOnly={true}
					title={"/images/hmp-more-icon-vert.svg"}
					options={[
						{
							title: this.T('Remove Property'),
							key: 'remove-property'
						}
					]}
					onChange={(val) => {
						switch (val.key) {
							case 'remove-property':
								this.props.dispatch(unClaimHome(this.state.addressInfo.buildingId));
								window.TRACK_EVENT({eventId: 'un-claim-home'});
							break;
							default: break;
						}
					}}
				/>
			);
		}

		let claimButton1 = !currentBuildingIsMine && this.state.insightFromMap && this.state.onboardData && this.onboardDataValid(this.state.onboardData);
		let claimButton2 = !claimButton1 && !currentBuildingIsMine && !this.state.insightFromMap;
		let claimButton3 = false;

		if (!this.props.currentUser) {
			claimButton1 = false;
			claimButton2 = false;
			claimButton3 = true;
		}

		return (
			<div className='homeowner-map-page' style={this.props.show ? { display: 'block'} : { display: 'none' }}>
				<div className='map-container' ref={this.mapContainer} aria-hidden={this.state.popupOpen ? 'true' : 'false'} />
				<div className='hmp-main-wrap'>
					<div role="alert">
					<div className={'loading ' + (this.state.loading ? 'loading-show' : 'loading-hide')}>{this.state.loading && <p className="sr-only">{this.T("Loading")}</p>}<img alt="" src='/images/loading-transparent.gif'/>{this.state.loadingClaiming ? <div className="loading-claiming-msg">{this.T("This may take a minute, please wait...")}</div> : undefined}</div>
					</div>
					{!this.props.userHasBuildings ? <div className={'new-hmp-search-block ' + (this.state.e1AddrFocus ? 'block-vis' : 'block-hidden')}></div> : undefined}
					{!this.props.userHasBuildings ? <div className={'new-hmp-search-bubble ' + (this.state.e1AddrFocus ? 'bubble-vis' : 'bubble-hidden')}>
						<img src="/images/hmp-bubble-dot.svg" className='hmp-bubble-dot' />
						<div className="hmp-info-bubble"><Trans i18nKey="new-search-info-bubble">To get started, Enter your home address or navigate the map to find and claim your home<br/><br/>If you already have an account, sign in.</Trans></div>
					</div> : undefined}
					<header role="none" className={'hmp-search new-hmp-search'} aria-hidden={this.state.popupOpen ? 'true' : 'false'} >
						<form name="search-home" onSubmit={(e) => {(e||window.event).preventDefault(); return false;}}>
							{!this.props.userHasBuildings ? <label className='search-title' for={this.inputID}><span className="hmp-new-step-no"><Trans key="hmp-step-1">Step 1</Trans></span> <Trans key="hmp-find-your-home">Find your home</Trans></label> : undefined}
							{this.props.userHasBuildings ? <label className='search-title' for={this.inputID}><Trans key="hmp-find-your-home">Find your home</Trans></label> : undefined}
							<AddressAutofill
								options={{
									language: `${this.T("hmp-lang-code")}`.toLowerCase(),
									country: 'CA'
								}}
								accessToken={config.accessToken}
								onRetrieve={this.mapboxSuggestionSelected}
								popoverOptions={{ offset: 15, keepOpen: false }}
								theme={{ variables: { 'borderRadius': '8px', fontFamily: 'Inter, sans-serif', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}}
							>
								<input id={this.inputID} aria-required={true} onFocus={() => {
									this.setState({ e1AddrFocus: document.getElementById(this.inputID).value?.length ? false : true })
								}} onBlur={() => {
									this.setState({ e1AddrFocus: false });
								}} name={this.inputID} autocomplete="off" type='text' className="search-box" placeholder={this.T("Enter your home address")} onKeyDown={(e) => {
									if ((e || window.event).which == 13) {
										this.searchClickAddress();
										this.LAST_SEARCH = 'address';
									}
								}} onKeyUp={(e) => {
									if (document.getElementById(this.inputID)) {
										this.setState({ e1AddrFocus: document.getElementById(this.inputID).value?.length ? false : true })
									}
								}}></input>
							</AddressAutofill>
						</form>
						<button onClick={() => {this.searchClickAddress(); this.LAST_SEARCH = 'address';}} aria-label={this.T("Search")} className='search-icon'><img src={'/images/hmp-search-qmark.svg'} alt={this.T("Search")} /></button>
					</header>
					{!this.state.showInsights && backToMyHomeBtn ? <div className="hmp-btmh-floating">{backToMyHomeBtn}</div> : undefined}
					{!this.state.showInsights ? <div className={'hmp-map-switcher ' + (backToMyHomeBtn ? 'hms-offset-y' : '')} onBlur={(e) => onBlurOutside(e, () => this.setState({mapSwitcherOpen: false}))}>
						{!this.theme?.whiteBuildingsMap ? <button tabIndex={this.state.popupOpen ? -1 : 0} className='hmms-btn' onFocus={() => this.setState({mapSwitcherOpen: true})} onClick={() => this.setState({mapSwitcherOpen: true})}><img src="/images/hmp-map-layers.svg"/><br/>{this.T('Layers')}</button> : undefined}
						<div role="radiogroup" aria-label={this.T('Layers')} className={'hmms-dropdown ' + (this.state.mapSwitcherOpen ? 'hmms-dd-show' : '')}>
							<button role="radio" onKeyDown={(e) => this.onRadioKeydown(e)} tabIndex={this.state.mapStyle === 'env' ? 0 : -1} aria-checked={this.state.mapStyle === 'env' ? "true" : "false"} className={'hmmsdd-map-option ' + (this.state.mapStyle === 'env' ? 'hmmsdd-map-selected' : '')} onClick={() => { this.setState({ ratingTab: 2, mapStyle: 'env' }); this.setStyle('env') } }><img className="rrt-img" alt={""} src="/images/hmp-env-impact.svg" /><div className="rrt-text"><Trans i18nKey="rrt-emissions">Emissions</Trans></div></button>
							<button role="radio" onKeyDown={(e) => this.onRadioKeydown(e)} tabIndex={this.state.mapStyle === 'env' ? -1 : 0} aria-checked={this.state.mapStyle === 'env' ? "false" : "true"} className={'hmmsdd-map-option ' + (this.state.mapStyle === 'eng' ? 'hmmsdd-map-selected' : '')} onClick={() => { this.setState({ ratingTab: 1, mapStyle: 'eng' }); this.setStyle('eng') } }><img className="rrt-img" alt={""} src="/images/hmp-energy-use.svg" /><div className="rrt-text"><Trans i18nKey="rrt-energy">Energy</Trans></div></button>
						</div>
					</div> : undefined}
					{this.renderIntro(this.state.introStep)}
					{window.GET_FF('enf-signin-flow-fix') ? this.renderTWModal() : undefined}
					{this.renderFAQModal()}
					{this.renderRetrofitFAQModal()}
					{this.renderConfirmClaimModal()}
					{this.state.addressInfo.addressFound && <div id="hmp-main-div" aria-label={this.T("Search Result")} className={"hmp-result " + (this.state.showInsights ? 'hmp-show ' : 'hmp-hide ') + (this.state.mobileMin ? 'mobile-min' : 'mobile-max') + (" censor-result-" + this.state.shareLevel) + ' new-hmp-result'} key={"hmp-result"} aria-hidden={this.state.popupOpen ? 'true' : 'false'} >
						<div className="res-mobile-min-cont" onClick={() => {this.setState({ mobileMin: !this.state.mobileMin })}}>
							<div className="res-mobile-min"></div>
						</div>
						<div className="res-header">
							{!this.props.userHasBuildings ? <label className='search-title' for={this.inputID}><span className="hmp-new-step-no"><Trans key="hmp-step-2">Step 2</Trans></span> <Trans key="hmp-claim-your-home">Claim Your Home</Trans></label> : undefined}
							{currentBuildingIsMine ? <img className="res-icon" alt={""} src="/images/hmp-home-icon.svg" /> : <img className="res-icon" alt={""} src="/images/hmp-home-icon-black.svg" />}
							<button autoFocus={true} className="res-x-button" onClick={() => {this.clearSearch()}}><img className="res-x-button" alt={this.T("Close Result")} src="/images/hmp-x-icon.svg"/></button>
							<h2 className="res-address">{this.state.addressInfo.displayAddress}</h2>
							{unclaimDropdown}
							{!this.state.hasBuildingPolygon ? <div className="res-404-cont">
								<div className="res-404-3">{this.T("We couldn’t place this building on the map, but all other features should work normally.")}</div><br/><br/>
							</div> : undefined}
							{(claimButton1 || claimButton2 || backToMyHomeBtn) ? <div className={"rhb-cont " + ((((claimButton1 || claimButton2) ? 1 : 0) + (backToMyHomeBtn ? 1 : 0)) === 1 ? 'rhbc-full' : '')}>
								{claimButton1 ? <button className="rhb-btn" onClick={this.claimHomeFromMapDataClick.bind(this)}>{this.T("Claim My Home")}</button> : undefined}
								{claimButton2 ? <button className="rhb-btn" onClick={this.claimHomeFromIDClick.bind(this)}>{this.T("Claim My Home")}</button> : undefined}
								{backToMyHomeBtn}
							</div> : undefined}
						</div>
						{(!this.props.currentUser || !this.state.onboardData || (this.onboardDataValid(this.state.onboardData) || this.state?.addressInfo?.insight?.buildingId)) ?
								(this.theme.whiteBuildingsMap ? <div/> : <CHomeRatingCard hideBreakdown={/*true || */!(this.state.shareLevel === 3)} rInfo={this.getRInfo()} updateParentState={(json) => {this.setState(json);}} homeRatingTab={this.state.homeRatingTab} areaSelected={this.state.areaSelected} modalOpenClick={this.modalOpenClick.bind(this)} overrideStats={this.state.addressInfo?.overrideStats} />)
							:
								!this.state.onboardSurvey ? (this.state.lastSearchType === 'address' ? <div className="hmp-claim-blank-home-address">
									<h2 className="hmp-cbh-msg-top"><Trans i18nKey={"hmp-cbh-addr-msg-top"}>We were unable to automatically match the address to a building on the map. Try clicking on the map to find a match for your address.</Trans></h2>
									<div className="hmp-cbh-msg-bottom"><Trans i18nKey={"hmp-cbh-addr-msg-bottom"}>You can click anywhere on the map (whether there is a building there or not). Most of the time, this resolves this issue<br/></Trans></div>
								</div> : <div className="hmp-claim-blank-home">
									<h2 className="hmp-cbh-msg-top"><Trans i18nKey={"hmp-cbh-msg-top"}>We do not have information for<br/>this building yet.</Trans></h2>
									<div className="hmp-cbh-msg-bottom"><Trans i18nKey={"hmp-cbh-msg-bottom"}>You can update this home and get a Home Energy Report with a short questionnaire.<br/>
									<br/>
									Note: If this building on the map is associated with the incorrect address, you can fix this in the questionnaire</Trans></div>
									<button className="rhb-btn" onClick={() => {this.setState({ onboardSurvey: true, rand: Math.random(), confirmAddress: document.getElementById(this.inputID).value, addressInUseError: false })}}>{this.T("Save As My Home")}</button>
								</div>)
								:
								<div className="hmp-claim-blank-home">
									<h3 className="hmp-cbh-title">{this.T('Update this short questionnaire to get a Home Energy Report')}</h3>
									<div className="hmp-cbh-address-cont">
										<label className="hmp-cbh-title" for={this.inputCBHID}>{this.T('Confirm address')}</label>
										<AddressAutofill
											options={{
												language: `${this.T("hmp-lang-code")}`.toLowerCase(),
												country: 'CA'
											}}
											accessToken={config.accessToken}
											onRetrieve={this.mapboxSuggestionSelectedConfirmAddress}
											popoverOptions={{ offset: 15, keepOpen: false }}
											theme={{ variables: { 'borderRadius': '8px', fontFamily: 'Inter, sans-serif', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}}
										>
											<input aria-describedby="hmp-cbh-warning-cont" id={this.inputCBHID} name={this.inputCBHID} value={this.state.confirmAddress} autocomplete="off" type='text' className="hmp-cbh-search-box" placeholder={this.T("Enter your home address")} onKeyDown={(e) => { if ((e || window.event).which == 13) { /* DO SOMETHING HERE */ } }} onChange={() => this.setState({ confirmAddress: document.getElementById(this.inputCBHID).value, addressChanged: document.getElementById(this.inputCBHID).value !== document.getElementById(this.inputID).value })} ></input>
										</AddressAutofill>
										{this.state.addressInUseError ? <div className="hmp-cbh-error"><Trans i18nKey={"hmp-cbh-error"}>The address you have entered is currently associated with another building. If the address you entered is correct, please contact us to correct the issue.</Trans>
										</div> : undefined}
										{!this.state.addressInUseError && this.state.addressChanged ? <div id="hmp-cbh-warning-cont" className="hmp-cbh-warning"><Trans i18nKey={"hmp-cbh-warning"}>The address you have entered is different from the address currently associated with this building. Is this the correct address?</Trans>
											<br/><br/>
											<button className="bmp-cbh-undo" onClick={() => {
												this.setState({ addressChanged: false, confirmAddress: document.getElementById(this.inputID).value });
											}}>{this.T("No, undo this change")}</button>
											<button className="bmp-cbh-confirm" onClick={() => {
												this.setState({ addressChanged: false });
											}}>{this.T("Yes, this is correct")}</button>
										</div> : undefined}
									</div>
									<CustomerWizard introSurvey={true} introSurveyCallback={this.claimHomeFromMiniSurveyClick.bind(this)} />
								</div>
						}
						{!(this.state.shareLevel === 3 && !this.props.currentUser) ? <div style={{height: '16px'}}/> : undefined}
						{/*false &&*/ (this.state.shareLevel === 3 && !this.props.currentUser) ? <div className="res-sharing-more-notice">
								<div className="res-smn-title">{this.T('Sharing More')}</div>
								<div className="res-smn-desc">{this.T('This homeowner has opted to share their Retrofit Scenarios and Energy use Breakdown publicly')}</div>
						</div> : undefined}
						{/*false &&*/ (this.state.shareLevel === 3) ? <CRetrofitScenariosCard noButtons={true} rInfo={this.getRInfo()} rInfoPackage={this.getRInfoPackage()} updateParentState={(json) => {this.setState(json);}} upgradePackageSelected={this.state.upgradePackageSelected} modalOpenClick={this.modalOpenClick.bind(this)} /> : undefined}
						{!this.props.currentUser ?
						<div className="res-cta new-res-cta">
							<div className="cta-title"><Trans i18nKey="hmp-cta-title-e1">Your Carbon- & Money-Saving Journey Starts Here</Trans></div>
							<button className="cta-button" onClick={() => this.clickCTA()}><Trans i18nKey="hmp-cta-button-title-e1">Claim My Home</Trans></button>
							<div className="cta-footer"><Trans i18nKey="hmp-cta-footer">Free Energy Savings Insights in 60 seconds</Trans></div>
							<div className="rrti-checks" role="list">
								<span role="listitem"><img src="/images/rrti-check.svg" alt="" /><span className="rrti-check"><Trans i18nKey="hmp-cta-check-1">Retrofit Savings Estimates</Trans></span></span>
								<span role="listitem"><img src="/images/rrti-check.svg" alt="" /><span className="rrti-check"><Trans i18nKey="hmp-cta-check-2">Estimated Retrofit Costs</Trans></span><br/></span>
								<span role="listitem"><img src="/images/rrti-check.svg" alt="" /><span className="rrti-check"><Trans i18nKey="hmp-cta-check-3">Energy Use Breakdown</Trans></span></span>
								<span role="listitem"><img src="/images/rrti-check.svg" alt="" /><span className="rrti-check"><Trans i18nKey="hmp-cta-check-4">Increase Accuracy by Updating Building Info</Trans></span></span>
							</div>
						</div> : undefined}
					</div>}
					{!this.state.addressInfo.addressFound && <div role="alert" id="hmp-main-div" className={"hmp-result hmp-result-404 mobile-max " + (this.state.showInsights ? 'hmp-show' : 'hmp-hide') + ' new-hmp-result'} key={"hmp-result"} aria-hidden={this.state.popupOpen ? 'true' : 'false'}>
						<div className="res-header">
							<img alt={""} className="res-icon" src="/images/hmp-home-grey-icon.svg" />
							<h2 className="res-address">{this.state.addressInfo.address}</h2>
							<img className="res-x-button" tabIndex={0} onKeyDown={(event)=> {if (event.key === "Enter") {this.clearSearch()}}} role="button" src="/images/hmp-x-icon.svg" aria-label={this.T("Close Result")} alt={this.T("Close Result")} onClick={() => {this.clearSearch()}}/>
						</div>
						<div className="res-404-cont">
							<img alt="Address not Found Icon" src="/images/hmp-res-404.svg"/><br/>
							<div className="res-404-1">{this.T(this.state.addressInfo.message || "Address Not Found")}</div><br/>
							<div className="res-404-2">{this.state.addressInfo.message2 || this.T("Please check address and try again")}</div>
							<div style={{width: "1px", height: "16px"}} />
						</div>
					</div>}
				</div>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
	let model = state.buildings.curModel || {};
	let lastUnclaimedBuildingId = state.auth.currentUser?.lastUnclaimedBuildingId ?? localStorage.getItem('lastUnclaimedBuildingId');
	return {
		building,
		model,
		currentUser: state.auth.currentUser ?? null,
		customerBuildings: state.auth.customerBuildings ?? null,
		currentBuildingIsMine: !!state.auth.currentUser && (building?.homeownerId === state.auth.currentUser?.id),
		lastUnclaimedBuildingId,
		currentBuildingIsClaimed: !!building?.homeownerId,
		userHasBuildings: !!state.auth.currentUser && state.auth.customerBuildings?.length
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(HomeownerMapPage)));
